import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 720.000000 720.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,720.000000) scale(0.100000,-0.100000)">
         

         <path d="M3993 4979 c-30 -19 -33 -26 -33 -70 0 -43 3 -51 25 -61 24 -11 25
-14 25 -112 l0 -102 -55 -54 -54 -54 -3 -110 -3 -111 -19 29 c-14 19 -22 53
-26 108 -5 72 -8 83 -33 104 -25 21 -38 24 -113 24 l-84 0 0 -485 0 -486 -30
-17 c-28 -16 -30 -21 -28 -67 3 -46 6 -51 40 -70 l37 -20 38 22 c34 20 38 26
41 68 3 41 1 47 -27 63 l-31 17 0 189 c0 167 2 187 16 182 9 -3 29 -6 45 -6
l29 0 0 -80 c0 -78 0 -80 -30 -95 -26 -14 -30 -21 -30 -60 0 -41 3 -46 42 -69
l43 -25 40 23 c37 22 40 28 41 70 2 42 -1 48 -27 62 -28 14 -29 17 -29 85 0
78 1 79 73 42 l27 -14 0 -219 0 -219 -30 -18 c-27 -15 -30 -23 -30 -65 0 -44
3 -49 37 -71 l37 -23 43 24 c40 22 43 26 43 67 0 35 -5 47 -25 62 l-25 18 2
209 3 209 59 -54 c73 -66 127 -148 158 -237 19 -55 23 -88 23 -184 l0 -118
303 0 302 0 0 151 0 151 -29 29 c-29 28 -44 33 -107 29 -51 -2 -111 19 -133
47 -35 43 -28 53 38 53 56 0 59 1 59 25 0 24 -2 25 -73 25 -67 0 -73 2 -83 25
-7 14 -12 41 -12 60 l0 35 190 0 190 0 0 23 0 22 -177 -3 -178 -3 39 40 c30
31 39 49 44 86 l5 47 -114 112 c-150 148 -173 153 -276 55 -60 -56 -83 -62
-83 -21 0 20 15 42 55 82 l55 54 0 112 c0 109 0 111 26 122 23 11 25 17 22 64
-3 47 -6 54 -37 72 l-34 19 -43 -23 c-42 -22 -44 -25 -44 -67 0 -39 3 -46 35
-64 l35 -21 0 -92 0 -92 -55 -54 c-50 -49 -55 -58 -55 -98 0 -48 0 -48 -77
-16 l-33 14 0 118 0 118 55 54 55 54 0 104 0 103 30 17 c26 15 30 22 30 63 0
42 -3 47 -39 67 -47 27 -48 27 -88 1z m68 -29 c25 -14 25 -61 -1 -78 -48 -29
-97 40 -52 74 21 16 28 17 53 4z m150 -210 c28 -15 26 -64 -3 -77 -35 -15 -68
3 -68 38 0 40 34 58 71 39z m-391 -1016 c0 -12 -10 -30 -22 -38 -47 -33 -93
40 -47 75 21 15 25 15 46 2 13 -9 23 -25 23 -39z m-140 -204 c0 -38 -24 -56
-58 -44 -36 13 -42 51 -11 75 32 24 69 7 69 -31z m280 -142 c0 -36 -36 -56
-69 -39 -29 16 -29 64 2 78 34 16 67 -3 67 -39z"/>
<path d="M1760 2970 l0 -210 145 0 145 0 0 35 0 35 -105 0 -105 0 0 175 0 175
-40 0 -40 0 0 -210z"/>
<path d="M3560 2970 l0 -210 35 0 35 0 0 95 c0 110 14 135 74 135 54 0 66 -26
66 -137 l0 -93 35 0 35 0 0 95 c0 119 -17 166 -67 190 -41 20 -96 15 -124 -11
-18 -17 -19 -15 -19 64 l0 82 -35 0 -35 0 0 -210z"/>
<path d="M5130 2970 l0 -210 30 0 c20 0 30 5 30 15 0 19 2 19 45 -6 62 -37
144 -13 186 54 16 25 19 45 17 94 -4 70 -27 105 -85 129 -44 19 -72 18 -116
-5 l-37 -19 0 79 0 79 -35 0 -35 0 0 -210z m219 -6 c46 -58 11 -144 -58 -144
-20 0 -46 7 -58 15 -30 21 -39 74 -19 113 18 35 28 40 78 41 28 1 41 -5 57
-25z"/>
<path d="M2823 3133 c-19 -4 -23 -11 -23 -39 0 -31 -2 -34 -30 -34 -27 0 -30
-3 -30 -29 0 -25 4 -30 28 -33 27 -3 27 -4 32 -95 4 -70 9 -96 24 -111 22 -25
65 -36 105 -27 26 5 28 9 22 33 -6 21 -14 27 -35 28 -42 2 -51 18 -51 97 l0
72 43 3 c39 3 42 5 42 33 0 27 -2 29 -40 29 l-40 0 0 40 c0 41 -1 42 -47 33z"/>
<path d="M4293 3133 c-19 -4 -23 -11 -23 -39 0 -31 -2 -34 -30 -34 -27 0 -30
-3 -30 -29 0 -25 4 -30 28 -33 27 -3 27 -4 32 -95 4 -70 9 -96 24 -111 22 -25
65 -36 105 -27 26 5 28 9 22 33 -6 21 -14 27 -35 28 -42 2 -51 18 -51 97 l0
72 43 3 c39 3 42 5 42 33 0 27 -2 29 -40 29 l-40 0 0 40 c0 41 -1 42 -47 33z"/>
<path d="M2583 3050 c-27 -16 -33 -17 -33 -5 0 10 -10 15 -30 15 l-30 0 0
-150 0 -150 35 0 35 0 0 100 c0 87 3 103 20 120 21 21 65 26 90 10 11 -7 18
-3 27 13 18 32 16 43 -12 55 -37 17 -65 15 -102 -8z"/>
<path d="M2171 3043 c-68 -34 -93 -165 -44 -231 46 -63 134 -77 195 -32 28 21
28 21 28 0 0 -16 6 -20 35 -20 l35 0 0 150 0 150 -35 0 c-29 0 -35 -4 -35 -21
l0 -20 -26 20 c-33 26 -105 28 -153 4z m150 -69 c54 -58 15 -154 -63 -154 -54
0 -88 35 -88 90 0 55 34 90 88 90 28 0 45 -7 63 -26z"/>
<path d="M3003 3048 c3 -7 26 -75 52 -150 l47 -138 42 0 43 0 28 92 28 92 29
-92 29 -92 42 0 42 0 51 150 52 150 -39 0 -38 0 -31 -99 c-16 -54 -30 -104
-30 -110 0 -35 -20 10 -46 104 -28 105 -28 105 -60 105 -31 0 -31 -1 -59 -97
-36 -129 -39 -133 -54 -81 -7 24 -22 73 -33 111 l-20 67 -40 0 c-28 0 -38 -4
-35 -12z"/>
<path d="M3891 3008 c11 -29 38 -95 59 -146 l39 -94 -29 -64 -29 -65 40 3 40
3 83 190 c45 105 85 198 89 208 5 14 0 17 -32 17 l-39 0 -43 -106 -42 -107
-37 104 -37 104 -42 3 -41 3 21 -53z"/>
<path d="M4543 3053 c-62 -12 -94 -92 -54 -132 20 -20 80 -41 117 -41 26 0 48
-24 41 -45 -11 -26 -56 -29 -108 -7 l-49 20 -15 -21 c-19 -27 -7 -41 51 -63
104 -39 212 23 190 109 -9 36 -45 58 -107 66 -86 10 -94 60 -11 61 18 0 41 -4
51 -10 14 -7 23 -5 36 10 22 24 13 37 -33 50 -37 10 -68 11 -109 3z"/>
<path d="M4770 2955 c0 -71 5 -115 14 -133 35 -67 99 -88 166 -53 33 17 40 18
40 6 0 -10 11 -15 35 -15 l35 0 0 150 0 150 -35 0 -35 0 0 -87 c0 -115 -20
-153 -79 -153 -60 0 -71 23 -71 142 l0 98 -35 0 -35 0 0 -105z"/>
<path d="M2583 2500 c-44 -18 -60 -102 -25 -133 52 -48 132 -24 132 38 0 33
-2 35 -35 35 -24 0 -35 -5 -35 -15 0 -9 9 -15 21 -15 34 0 19 -34 -17 -38 -36
-3 -56 16 -56 53 0 41 21 58 64 52 43 -6 59 7 29 22 -23 13 -48 13 -78 1z"/>
<path d="M2720 2425 c0 -69 3 -85 15 -85 12 0 15 16 15 85 0 69 -3 85 -15 85
-12 0 -15 -16 -15 -85z"/>
<path d="M2910 2426 l0 -83 43 0 c23 -1 49 3 58 8 24 12 30 60 12 87 -12 19
-22 23 -49 20 -32 -4 -34 -2 -34 24 0 18 -5 28 -15 28 -12 0 -15 -16 -15 -84z
m90 -1 c20 -25 8 -50 -25 -50 -21 0 -31 5 -33 18 -4 21 14 47 33 47 7 0 18 -7
25 -15z"/>
<path d="M3210 2425 c0 -69 3 -85 15 -85 12 0 15 16 15 85 0 69 -3 85 -15 85
-12 0 -15 -16 -15 -85z"/>
<path d="M3310 2425 l0 -85 65 0 c51 0 65 3 65 15 0 11 -12 15 -50 15 -43 0
-50 3 -50 20 0 17 7 20 45 20 33 0 45 4 45 15 0 11 -12 15 -45 15 -38 0 -45 3
-45 20 0 17 7 20 50 20 38 0 50 4 50 15 0 12 -14 15 -65 15 l-65 0 0 -85z"/>
<path d="M4186 2503 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
<path d="M3596 2468 c-13 -14 -15 -23 -8 -26 7 -2 12 -19 12 -37 0 -35 21 -65
46 -65 8 0 14 7 14 15 0 8 -7 15 -15 15 -10 0 -15 11 -15 35 0 24 5 35 15 35
8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15 7 -15 15 0 21 -12 19 -34 -7z"/>
<path d="M2782 2448 c-21 -21 -15 -75 10 -92 29 -21 51 -20 71 2 20 22 22 73
5 90 -7 7 -26 12 -43 12 -17 0 -36 -5 -43 -12z m71 -29 c15 -24 1 -49 -27 -49
-29 0 -44 33 -25 56 15 18 38 15 52 -7z"/>
<path d="M3080 2443 c-23 -16 -28 -74 -7 -92 8 -6 35 -10 60 -9 l47 2 0 57 0
56 -40 0 c-22 0 -49 -6 -60 -14z m58 -15 c17 -17 15 -43 -4 -59 -31 -26 -70
25 -44 56 15 18 32 19 48 3z"/>
<path d="M3460 2398 c0 -44 4 -58 14 -58 10 0 16 14 18 43 3 37 6 42 28 42 22
0 25 -5 28 -42 4 -60 22 -54 22 6 0 57 -8 64 -67 67 l-43 1 0 -59z"/>
<path d="M3712 2444 c-50 -35 -8 -115 53 -100 37 9 31 26 -9 26 -19 0 -38 5
-41 10 -4 6 13 10 45 10 49 0 51 1 45 23 -14 45 -54 59 -93 31z m58 -19 c11
-13 8 -15 -19 -15 -31 0 -42 14 -18 23 20 9 24 8 37 -8z"/>
<path d="M3830 2399 c0 -45 3 -59 14 -59 10 0 16 14 18 42 2 35 7 43 26 48 39
9 30 25 -15 27 l-43 1 0 -59z"/>
<path d="M3930 2374 c0 -68 3 -84 15 -84 10 0 15 10 15 31 0 26 3 29 20 24 30
-10 56 2 69 32 10 23 9 30 -7 52 -15 21 -28 26 -66 27 l-46 2 0 -84z m95 26
c0 -20 -6 -26 -28 -28 -18 -2 -29 2 -33 12 -11 28 4 48 33 44 22 -2 28 -8 28
-28z"/>
<path d="M4080 2399 c0 -45 3 -59 14 -59 10 0 16 14 18 42 2 35 7 43 26 48 39
9 30 25 -15 27 l-43 1 0 -59z"/>
<path d="M4180 2400 c0 -47 3 -60 15 -60 12 0 15 13 15 60 0 47 -3 60 -15 60
-12 0 -15 -13 -15 -60z"/>
<path d="M4255 2446 c-25 -18 -13 -45 25 -52 17 -4 30 -13 30 -22 0 -11 -8
-13 -36 -9 -25 4 -34 2 -31 -6 2 -7 20 -13 40 -15 28 -2 38 1 47 18 15 27 1
45 -41 54 -18 3 -28 10 -24 17 4 6 18 8 30 5 27 -7 43 9 19 18 -24 9 -38 7
-59 -8z"/>
<path d="M4382 2444 c-50 -35 -8 -115 53 -100 37 9 31 26 -9 26 -19 0 -38 5
-41 10 -4 6 13 10 45 10 49 0 51 1 45 23 -14 45 -54 59 -93 31z m58 -19 c11
-13 8 -15 -19 -15 -31 0 -42 14 -18 23 20 9 24 8 37 -8z"/>
</g>
</svg>

        
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}